import NOOP from 'constants/noop';
import { TPermissionContext } from './PermissionProvider.types';

export const INITIAL_STATE: TPermissionContext = {
  permissionState: {
    permissions: [],
    setPermissions: NOOP,
    hasAccess: () => false,
  },
};
