import { TPalette } from './theme.types';

const palette: TPalette = {
  blue900: '#0E4571',
  blue800: '#104D7E',
  blue700: '#285F8B',
  blue600: '#407198',
  blue500: '#5882A5',
  blue400: '#7094B2',
  blue300: '#88A6BF',
  blue200: '#9FB8CB',
  blue100: '#cfdbe5',

  riseYellow900: '#F9E134',
  riseYellow800: '#F9E134',
  riseYellow700: '#FAE448',
  riseYellow600: '#FAE75D',
  riseYellow500: '#FBEA71',
  riseYellow400: '#FBED85',
  riseYellow300: '#FCF09A',
  riseYellow200: '#FDF3AE',
  riseYellow100: '#FDF6C2',

  skyBlue900: '#96D1DA',
  skyBlue800: '#A7E8F2',
  skyBlue700: '#B0EAF3',
  skyBlue600: '#B9EDF5',
  skyBlue500: '#C1EFF6',
  skyBlue400: '#CAF1F7',
  skyBlue300: '#D3F4F9',
  skyBlue200: '#DCF6FA',
  skyBlue100: '#E5F8FB',

  teal900: '#19A0B3',
  teal800: '#1CB2C7',
  teal700: '#33BACD',
  teal600: '#49C1D2',
  teal500: '#60C9D8',
  teal400: '#77D1DD',
  teal300: '#8ED9E3',
  teal200: '#A4E0E9',
  teal100: '#BBE8EE',

  wamoLight900: '#DEDAD2',
  wamoLight800: '#F7F2E9',
  wamoLight700: '#F8F3EB',
  wamoLight600: '#F9F5ED',
  wamoLight500: '#F9F6F0',
  wamoLight400: '#FAF7F2',
  wamoLight300: '#FBF9F4',
  wamoLight200: '#FCFAF6',
  wamoLight100: '#FDFBF8',

  gray900: '#212529',
  gray800: '#343A40',
  gray700: '#495057',
  gray600: '#868E96',
  gray500: '#ADB5BD',
  gray400: '#CED4DA',
  gray300: '#DEE2E6',
  gray200: '#E9ECEF',
  gray100: '#F1F3F5',
  gray50: '#F8F9FA',
  gray: '#FDFDFE',

  white: '#FFFFFF',

  red900: '#E03131',
  red800: '#F03E3E',
  red700: '#FA5252',
  red50: '#FFF5F5',

  green900: '#2F9E44',
  green800: '#37B24D',
  green700: '#40C057',
  green50: '#EBFBEE',

  yellow900: '#F59F00',
  yellow800: '#FAB005',
  yellow700: '#FCC419',
  yellow50: '#FFF9DB',

  transparent: 'transparent',

  antdChart1: '#1890FF',
  antdChart2: '#2FC25B',
  antdChart3: '#FACC14',
  antdChart4: '#8543E0',
  antdChart5: '#F04864',
  antdChart6: '#13C2C2',
  antdChart7: '#fa8c16',
  antdChart8: '#a0d911',
};

export default palette;
