import React from 'react';
import { App, ConfigProvider } from 'antd';
import { useTheme } from 'styled-components';

import { TChildrenOnly } from 'types/common';

function AntdConfigProvider({ children }: TChildrenOnly) {
  const { colors } = useTheme();
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,

          colorPrimary: colors.primary,

          colorSuccess: colors.success,
          colorSuccessBg: colors.successBackground,
          colorWarning: colors.warning,
          colorWarningBg: colors.warningBackground,
          colorError: colors.error,
          colorErrorBg: colors.errorBackground,
          colorInfo: colors.primary,
          colorInfoBg: colors.infoBackground,

          colorLink: colors.primary,
        },
        components: {
          Button: {
            controlOutlineWidth: 0,
          },
        },
      }}>
      <App>{children}</App>
    </ConfigProvider>
  );
}

export default AntdConfigProvider;
