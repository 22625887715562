import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

function WamoIcon({ iconColor = '#104D7E', ...rest }: TIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={198}
      height={96}
      viewBox="0 0 198 96"
      fill="none"
      {...rest}>
      <path
        d="M196.971 31.3l-6-17.6c-2.6-7.6-10.6-13.6-18.3-13.6h-17.5c-7.7 0-15.7 6-18.3 13.6l-5.5 16.2-5.5-16.2c-2.5-7.7-10.5-13.7-18.1-13.7h-17.5c-6.3 0-12.9 4.1-16.5 9.8-.8 1.2-1.4 2.5-1.8 3.8l-5.5 16.3-5.5-16.3C58.371 6 50.271 0 42.671 0H25.171c-7.6 0-15.7 6-18.3 13.6l-6 17.6c-.1.3-2.3 6.6.7 11 1.6 2.4 4.2 3.7 7.8 3.8h17.5v-.4l12.4 36.6c2.6 7.6 10.6 13.6 18.3 13.6h17.5c7.7 0 15.7-6 18.3-13.6l-1.9-.6-.1-.1 2 .7 5.6-16.2 5.5 16.4c2.6 7.6 10.6 13.6 18.3 13.6h17.4c7.7 0 15.7-6 18.3-13.6l12.2-36.1h17.8c3.6-.2 6.3-1.5 7.8-3.8 3-4.6.8-10.9.7-11.2zM9.571 42.1c-2.3-.1-3.8-.8-4.6-2.1-1.5-2.3-.7-6.2-.2-7.5l5.9-17.4c1-2.1 2.8-2.1 3-2.1h.1c2 0 2.7 1.7 2.8 1.9l9.2 27.2h-16.2zm65.5 49.8h-17.4c-5.9 0-12.5-5-14.5-10.9l-22.8-67.4c-.6-1.6-2.4-4.3-5.8-4.6 3-3 7-5 10.7-5h17.4c5.8 0 12.4 5 14.4 10.9l22.9 67.4v.1c.6 1.6 2.5 4.2 5.9 4.5-3 3-7 5-10.8 5zm14.6-11.1c-1.1 2.1-2.8 2.1-3 2.1h-.1c-2 0-2.8-1.7-2.9-1.9l-15.2-44.8 7.1-21.1c1-2.1 2.8-2.1 3-2.1h.1c2 0 2.8 1.7 2.9 1.9l15.2 44.8-7.1 21.1zm33.1 11.1c-5.9 0-12.5-5-14.5-10.9l-22.8-67.4v-.1c-.6-1.6-2.5-4.2-5.9-4.5 3-3 7-5 10.7-5h17.5c5.9 0 12.5 5 14.5 10.9l7.4 21.9 15.4 45.5v.1c.6 1.6 2.5 4.2 5.9 4.5-3 3-7 5-10.7 5h-17.5zm32-11c-1 2-2.8 2.1-2.9 2.1h-.1c-2 0-2.8-1.7-2.9-1.9l-15.2-44.9 7.1-21c1-2.1 2.8-2.1 3-2.1h.1c2 0 2.8 1.7 2.9 1.9l6 17.6c2.1 6.2 7.9 11.3 14.1 13l-12.1 35.3zm38.2-40.8c-.9 1.3-2.4 1.9-4.7 2.1h-17.4c-5.9 0-12.5-5-14.5-10.9l-6-17.6v-.1c-.6-1.6-2.5-4.2-5.9-4.5 3-3 7-5 10.7-5h17.5c5.9 0 12.5 5 14.5 10.9l6 17.7s1.6 4.7-.2 7.4z"
        fill={iconColor}
      />
    </svg>
  );
}

export default WamoIcon;
