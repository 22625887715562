import React, { ReactElement } from 'react';
import { Layout } from 'antd';

import Sidebar from 'components/core/Sidebar';

import { ContentLayout, StyledLayout } from './DashboardLayout.style';
import { TDashboardLayoutProps } from './DashboardLayout.types';
import Header from './views/Header';

function DashboardLayout({ children }: TDashboardLayoutProps): ReactElement {
  return (
    <StyledLayout>
      <Sidebar />
      <Layout>
        <ContentLayout>
          <Header />
          {children}
        </ContentLayout>
      </Layout>
    </StyledLayout>
  );
}

export default DashboardLayout;
