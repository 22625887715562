import React, { createContext, ReactElement, useContext, useState } from 'react';
import { useFetch } from 'use-http';
import { isEmpty } from 'lodash/fp';

import API_ENDPOINT from 'constants/apiEndpoint';
import { TChildrenOnly } from 'types/common';

import { INITIAL_DISPATCH, INITIAL_STATE } from './CountriesProvider.constants';
import { TCountriesState, TCountriesContext } from './CountriesProvider.types';

const countriesContext = createContext<TCountriesContext>({
  countriesState: INITIAL_STATE,
  countriesDispatch: INITIAL_DISPATCH,
});

function CountriesProvider({ children }: TChildrenOnly): ReactElement {
  const { get } = useFetch(API_ENDPOINT.COUNTRIES);
  const [countries, setCountries] = useState<TCountriesState['countries']>(INITIAL_STATE.countries);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  async function handleFetchCountries() {
    try {
      if (isEmpty(countries)) {
        setInitialLoading(true);
      }

      const response = await get();

      if (response?.result) {
        setCountries(response.result);
      }

      return response?.result ?? [];
    } catch (e) {
      return [];
    } finally {
      setInitialLoading(false);
    }
  }

  const value: TCountriesContext = {
    countriesState: { countries, loading: initialLoading },
    countriesDispatch: { getCountries: handleFetchCountries },
  };

  return <countriesContext.Provider value={value}>{children}</countriesContext.Provider>;
}

export default CountriesProvider;

export const useCountriesContext = (): TCountriesContext => useContext(countriesContext);
