import palette from 'theme/palette';
import { TThemePrimitives } from './theme.types';

const lightThemePrimitives: TThemePrimitives = {
  primary: palette.blue800,
  transparent: palette.transparent,

  lightText: palette.gray600,

  primaryBackground: palette.white,
  secondaryBackground: palette.blue800,
  layoutBackground: palette.gray100,

  success: palette.green900,
  successBackground: palette.green50,
  warning: palette.yellow900,
  warningBackground: palette.yellow50,
  error: palette.red900,
  errorBackground: palette.red50,
  info: palette.blue800,
  infoBackground: palette.skyBlue100,

  tagDefaultBackground: palette.gray50,
  tagDefaultBorder: palette.gray400,
  tagDefaultText: palette.gray900,
  tagSuccessBackground: palette.green50,
  tagSuccessText: palette.green900,
  tagWarningBackground: palette.yellow50,
  tagWarningText: palette.yellow900,
  tagProcessingBackground: palette.skyBlue100,
  tagProcessingText: palette.teal900,
  tagErrorBackground: palette.red50,
  tagErrorText: palette.red900,

  sidebarLogo: palette.wamoLight800,

  tableSuccessRowBackground: palette.green700,
  tableDeclinedRowBackground: palette.red900,
  tablePendingRowBackground: palette.teal900,

  chart1: palette.antdChart1,
  chart2: palette.antdChart2,
  chart3: palette.antdChart3,
  chart4: palette.antdChart4,
  chart5: palette.antdChart5,
  chart6: palette.antdChart6,
  chart7: palette.antdChart7,
  chart8: palette.antdChart8,

  appleIcon: '#A2AAAD',
  androidIcon: '#99CC33',

  countStatisticIcon: palette.gray300,

  contentDescriptionBorder: palette.gray300,
  contentDescriptionBackground: palette.gray50,

  customBillingInfoTooltipBackground: palette.blue800,
  customBillingInfoRibbon: palette.teal800,

  billingItemUpdateInfoBorder: palette.teal800,
  billingItemUpdateInfoIconBackground: palette.teal800,
  billingItemUpdateInfoIconTitleText: palette.teal800,
};

export default lightThemePrimitives;
