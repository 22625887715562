import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { TTextProps } from './Typography.types';
import { StyledAntdText } from './Typography.styles';

function Text({
  id,
  textValues,
  testID,
  children,
  comma = false,
  ...rest
}: TTextProps): ReactElement {
  return (
    <StyledAntdText data-testid={testID || 'text'} comma={comma} {...rest}>
      {id ? (
        <Trans
          i18nKey={id}
          values={textValues}
          components={{
            b: <b />,
          }}
        />
      ) : (
        children
      )}
    </StyledAntdText>
  );
}

export default Text;
