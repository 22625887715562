import styled from 'styled-components';
import { Layout } from 'antd';

import { WamoIcon, WamoTextIcon } from 'components/Icons';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  overflow: auto;
  margin-bottom: 48px;

  .ant-menu {
    padding-bottom: 64px;
  }
`;

export const StyledWamoTextIcon = styled(WamoTextIcon)`
  height: 50px;
  margin-left: 8px;
`;

export const StyledWamoIcon = styled(WamoIcon)`
  width: 58px;
  min-width: 58px;
  height: 50px;
`;
