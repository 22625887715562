import React, { ReactElement } from 'react';

import { TChildrenOnly } from 'types/common';
import ThemeProvider from './ThemeProvider';
import FetchProvider from './FetchProvider';
import PermissionProvider from './PermissionProvider';
import AntdConfigProvider from './AntdConfigProvider';
import CountriesProvider from './CountriesProvider';
import DocumentTypesProvider from './DocumentTypesProvider';

function AllContextProvider({ children }: TChildrenOnly): ReactElement {
  return (
    <FetchProvider>
      <PermissionProvider>
        <ThemeProvider>
          <DocumentTypesProvider>
            <CountriesProvider>
              <AntdConfigProvider>{children}</AntdConfigProvider>
            </CountriesProvider>
          </DocumentTypesProvider>
        </ThemeProvider>
      </PermissionProvider>
    </FetchProvider>
  );
}

export default AllContextProvider;
