import React, { memo, ReactElement, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BookOutlined,
  EuroOutlined,
  FileSearchOutlined,
  HomeOutlined,
  IdcardOutlined,
  PartitionOutlined,
  TagOutlined,
  UserOutlined,
  GiftOutlined,
  CreditCardOutlined,
  BankOutlined,
  AuditOutlined,
  AlertOutlined,
  SyncOutlined,
  SwapOutlined,
} from '@ant-design/icons';

import { usePermissionState } from 'context/PermissionProvider';
import routes from 'router/routes';
import { TI18nId } from 'types/common';
import storage, { STORAGE_KEYS } from 'helpers/storage';

import Logo from './views/Logo';
import { TRenderMenuItemProps } from './Sidebar.types';
import { StyledSider } from './Sidebar.style';

function Sidebar(): ReactElement {
  const { hasAccess } = usePermissionState();
  const { t } = useTranslation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(
    !!storage.readStorage(STORAGE_KEYS.SIDEBAR_COLLAPSED)
  );

  function onCollapse(collapsed: boolean) {
    setCollapsed(collapsed);
    if (collapsed) {
      storage.writeStorage(STORAGE_KEYS.SIDEBAR_COLLAPSED, 'true');
    } else {
      storage.removeStorage(STORAGE_KEYS.SIDEBAR_COLLAPSED);
    }
  }

  function renderMenuItem({
    path,
    menuItemId,
    icon: Icon,
    expectedPermission,
  }: TRenderMenuItemProps): ReactElement | null {
    if (expectedPermission && !hasAccess(expectedPermission)) {
      return null;
    }
    return (
      <Menu.Item key={path} icon={<Icon />}>
        <NavLink to={path} exact strict>
          {t(menuItemId)}
        </NavLink>
      </Menu.Item>
    );
  }

  return (
    <StyledSider collapsible collapsed={collapsed} onCollapse={onCollapse} width={250}>
      <Logo collapsed={collapsed} />
      <Menu theme="dark" selectedKeys={[history.location.pathname]} mode="inline">
        {renderMenuItem({
          path: routes.home.path,
          menuItemId: routes.home.menuItemId as TI18nId,
          icon: HomeOutlined,
          expectedPermission: routes.home.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.memberList.path,
          menuItemId: routes.memberList.menuItemId as TI18nId,
          icon: UserOutlined,
          expectedPermission: routes.memberList.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.merchantList.path,
          menuItemId: routes.merchantList.menuItemId as TI18nId,
          icon: BankOutlined,
          expectedPermission: routes.merchantList.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.onboarding.path,
          menuItemId: routes.onboarding.menuItemId as TI18nId,
          icon: AlertOutlined,
          expectedPermission: routes.onboarding.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.transactions.path,
          menuItemId: routes.transactions.menuItemId as TI18nId,
          icon: EuroOutlined,
          expectedPermission: routes.transactions.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.payments.path,
          menuItemId: routes.payments.menuItemId as TI18nId,
          icon: EuroOutlined,
          expectedPermission: routes.payments.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.payouts.path,
          menuItemId: routes.payouts.menuItemId as TI18nId,
          icon: PartitionOutlined,
          expectedPermission: routes.payouts.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.cashbackCampaigns.path,
          menuItemId: routes.cashbackCampaigns.menuItemId as TI18nId,
          icon: GiftOutlined,
          expectedPermission: routes.cashbackCampaigns.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.cards.path,
          menuItemId: routes.cards.menuItemId as TI18nId,
          icon: CreditCardOutlined,
          expectedPermission: routes.cards.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.billingPackages.path,
          menuItemId: routes.billingPackages.menuItemId as TI18nId,
          icon: TagOutlined,
          expectedPermission: routes.billingPackages.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.merchantFees.path,
          menuItemId: routes.merchantFees.menuItemId as TI18nId,
          icon: BookOutlined,
          expectedPermission: routes.merchantFees.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.complianceRequests.path,
          menuItemId: routes.complianceRequests.menuItemId as TI18nId,
          icon: FileSearchOutlined,
          expectedPermission: routes.complianceRequests.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.adminUser.path,
          menuItemId: routes.adminUser.menuItemId as TI18nId,
          icon: IdcardOutlined,
          expectedPermission: routes.adminUser.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.auditLogs.path,
          menuItemId: routes.auditLogs.menuItemId as TI18nId,
          icon: AuditOutlined,
          expectedPermission: routes.auditLogs.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.automatedBulkTransfers.path,
          menuItemId: routes.automatedBulkTransfers.menuItemId as TI18nId,
          icon: SyncOutlined,
          expectedPermission: routes.automatedBulkTransfers.expectedPermission,
        })}
        {renderMenuItem({
          path: routes.visaReconciliation.path,
          menuItemId: routes.visaReconciliation.menuItemId as TI18nId,
          icon: SwapOutlined,
          expectedPermission: routes.visaReconciliation.expectedPermission,
        })}
      </Menu>
    </StyledSider>
  );
}

export default memo(Sidebar);
