import React, { ReactElement } from 'react';

import { TIconProps } from '../Icon.types';

function WamoTextIcon({ iconColor = '#104D7E', ...rest }: TIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={466}
      height={88}
      viewBox="0 0 466 88"
      fill="none"
      {...rest}>
      <path
        d="M380.971 42.6c0-25.7 19.3-42.4 42.5-42.4 23.1 0 42.5 16.6 42.5 42.4 0 25.8-19.4 42.7-42.5 42.7-23.2 0-42.5-16.9-42.5-42.7zm63.9 0c0-13-9.6-21.4-21.4-21.4-11.8 0-21.4 8.4-21.4 21.4 0 13.3 9.6 21.9 21.4 21.9 11.8 0 21.4-8.6 21.4-21.9zM121.671 16.8c.6-1.3.9-2.8.9-4.3 0-5.4-4.1-9.9-9.4-10.5v-.1h-1c-4.7 0-8.6 3-10 7.2v.2l-14.5 44.2-13.5-41.8c-1.4-5.6-6.5-9.8-12.6-9.8-5.7 0-10.6 3.7-12.3 8.9h-.1l-13.6 42.8-14.3-44.4h-.1c-1.3-3.9-4.8-6.8-9-7.1h-1c-5.8 0-10.5 4.7-10.5 10.5 0 1.5.3 2.9.9 4.2l23.5 63.5c1.3 4.2 5.3 7.3 10 7.3s8.7-3.1 10-7.4l16.6-51.2 16.9 51c0 .1.1.2.1.3 1.4 4.3 5.4 7.4 10.1 7.4 4.8 0 8.8-3.1 10.1-7.5 0-.1 0-.1.1-.2l22.7-63.2zM218.671 12.4c-.1-5.8-4.8-10.4-10.6-10.4-5.5 0-10 4.1-10.6 9.5v.7c-6-9.1-18-11.9-25.4-11.9-21.5 0-41.7 16.3-41.7 42.5 0 26.1 20.1 42.5 41.7 42.5 8.1 0 20.1-3.7 25.4-12.1v1.4c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7l-.2-62.2zm-43.7 52.5c-11.7 0-22.6-8.9-22.6-22.1 0-13.3 10.9-22.1 22.6-22.1 11.2 0 22.4 7.9 22.4 22.1 0 14-10.9 22.1-22.4 22.1zM366.271 37c0-24-11.7-36.8-31.5-36.8-6.5 0-18 1.9-25.7 15.4-4.6-10-13.1-14.5-26.1-15.2-7.7 0-18.9 3.7-23.5 13.7v-.7-.7c0-5.9-4.8-10.7-10.7-10.7-5.8 0-10.5 4.6-10.7 10.3v62.3c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7V40.2c0-14 8.4-19.1 17.2-19.1 8.4 0 14.9 6.3 15.1 17.7v35.9c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7V40.2c0-12.4 7-19.1 16.6-19.1 8.8 0 15.4 6.5 15.4 18.2v34.3c0 .4-.1.7-.1 1.1 0 5.9 4.8 10.6 10.6 10.6s10.6-4.8 10.6-10.6V37z"
        fill={iconColor}
      />
    </svg>
  );
}

export default WamoTextIcon;
