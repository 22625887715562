import React, { ReactElement } from 'react';

import { WamoIcon } from 'components/Icons';
import { Title, Text } from 'components/core/Typography';
import Box from 'components/core/Box';

function NotFound(): ReactElement {
  return (
    <Box alignment="center" backgroundColor="secondaryBackground" height="100vh">
      <Box backgroundColor="primaryBackground" width="500px" padding="xxl" borderRadius="8px">
        <Box alignment="row.top.right" marginBottom="m">
          <Box flex={1}>
            <Title id="pages.notFound.title" />
          </Box>
          <WamoIcon width="80" height="40" />
        </Box>
        <Text fontSize="large" id="pages.notFound.description" />
      </Box>
    </Box>
  );
}

export default NotFound;
