import storage, { STORAGE_KEYS } from 'helpers/storage';
import { TMe } from './useMe.types';

function useMe(): TMe | undefined {
  try {
    return JSON.parse(storage.readStorage(STORAGE_KEYS.ACCOUNT) as string);
  } catch (e) {
    return undefined;
  }
}

export default useMe;
