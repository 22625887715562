import React, { ReactElement, memo } from 'react';
import { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

import Box from 'components/core/Box';

import routes from 'router/routes';
import { TTheme } from 'theme/theme.types';
import { StyledWamoIcon, StyledWamoTextIcon } from '../Sidebar.style';
import { TLogoProps } from '../Sidebar.types';

function Logo({ collapsed }: TLogoProps): ReactElement {
  const { colors } = useTheme() as TTheme;
  return (
    <NavLink to={routes.home.path} exact strict>
      <Box marginY="l" paddingX="xl" alignment={collapsed ? 'center' : 'row.verticalCenter'}>
        <StyledWamoIcon iconColor={colors.sidebarLogo} />
        {!collapsed && <StyledWamoTextIcon iconColor={colors.sidebarLogo} />}
      </Box>
    </NavLink>
  );
}

export default memo(Logo);
