import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as AntdTooltip } from 'antd';

import { TTooltipProps } from './Tooltip.types';

function Tooltip({ titleId, title, children, ...rest }: TTooltipProps): ReactElement {
  const { t } = useTranslation();

  return (
    <AntdTooltip title={titleId ? t(titleId) : title} {...rest}>
      {children}
    </AntdTooltip>
  );
}

export default Tooltip;
