import { styleFn } from 'styled-system';

import { TSpacing, TTheme, TThemePrimitives } from 'theme/theme.types';

function getColor(name: keyof TThemePrimitives): styleFn {
  return ({ theme }: { theme: TTheme }) => theme.colors[name];
}

function getSpacing(space: keyof TSpacing): styleFn {
  return ({ theme }: { theme: TTheme }) => theme.space[space];
}

export { getColor, getSpacing };
