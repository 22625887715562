import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TTitleProps } from './Typography.types';
import { StyledAntdTitle } from './Typography.styles';

function Title({ id, children, comma = false, ...rest }: TTitleProps): ReactElement {
  const { t } = useTranslation();

  return (
    <StyledAntdTitle comma={comma} {...rest}>
      {id && t(id)}
      {children}
    </StyledAntdTitle>
  );
}

export default Title;
