import { Typography } from 'antd';
import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight, lineHeight, margin, padding, textAlign } from 'styled-system';

import spacing from 'theme/spacing';
import { TTextProps } from './Typography.types';

const { Text: AntdText, Title: AntdTitle } = Typography;

export const StyledAntdText = styled(AntdText)<TTextProps>`
  &,
  &.ant-typography {
    ${color};
    ${margin};
    ${padding};
    ${fontSize};
    ${fontWeight};
    ${textAlign};
  }

  ${({ comma }) =>
    comma &&
    css`
      padding-right: ${spacing.s}px;

      &:after {
        content: ':';
      }
    `}
`;

export const StyledAntdTitle = styled(AntdTitle)<{ comma: boolean }>`
  &,
  &.ant-typography {
    ${color};
    ${margin};
    ${padding};
    ${textAlign};
    ${lineHeight};
  }

  ${({ comma }) =>
    comma &&
    css`
      padding-right: ${spacing.s}px;

      &:after {
        content: ':';
      }
    `}
`;
