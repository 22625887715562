import React, { ReactElement } from 'react';
import { Dropdown, Menu } from 'antd';

import Tooltip from 'components/core/Tooltip';
import { Text } from 'components/core/Typography';

import useMe from 'hooks/useMe';
import useLogout from 'hooks/useLogout';
import { StyledProfileAvatar } from '../DashboardLayout.style';

function ProfileDropdownButton(): ReactElement {
  const logout = useLogout();
  const me = useMe();
  const { firstName = '', lastName = '' } = me || {};

  function renderProfileContent(): ReactElement {
    return (
      <Menu>
        <Menu.Item key="0" onClick={logout}>
          <Text id="button.logout" />
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Dropdown overlay={renderProfileContent} trigger={['click']}>
      <Tooltip title={[firstName, lastName].join(' ')} placement="left" mouseEnterDelay={0.5}>
        <StyledProfileAvatar>{`${[firstName, lastName]
          .map((v) => v.charAt(0).toUpperCase())
          .join('')}`}</StyledProfileAvatar>
      </Tooltip>
    </Dropdown>
  );
}

export default ProfileDropdownButton;
