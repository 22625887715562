import React, { Fragment, ReactElement, Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import Loading from 'components/Loading';

import NotFound from 'pages/NotFound';
import { useCountriesContext } from 'context/CountriesProvider';
import storage, { STORAGE_KEYS } from 'helpers/storage';
import { usePermissionState } from 'context/PermissionProvider';
import { useDocumentTypesContext } from 'context/DocumentTypesProvider';
import routes from './routes';
import PermissionDeniedPage from './views/PermissionDeniedPage';

const APP_ROUTES = Object.entries(routes);

function AppRouter(): ReactElement {
  const { hasAccess } = usePermissionState();
  const { countriesDispatch } = useCountriesContext();
  const { documentTypesDispatch } = useDocumentTypesContext();

  async function getInitialRequests() {
    const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      Promise.all([countriesDispatch.getCountries(), documentTypesDispatch.getDocumentTypes()]);
    }
  }

  useEffect(() => {
    getInitialRequests();
  }, []);

  return (
    <Router>
      <Switch>
        {APP_ROUTES.map(
          ([
            key,
            {
              component: Component,
              loadingIndicator,
              isPrivate,
              isRestricted,
              layout: Layout = Fragment,
              expectedPermission,
              ...rest
            },
          ]) => (
            <Route
              key={key}
              {...rest}
              render={({ location }) => {
                const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);

                if (isPrivate && !accessToken) {
                  return (
                    <Redirect
                      key={key}
                      to={{
                        pathname: routes.login.path,
                        state: { redirectTo: location.pathname },
                      }}
                    />
                  );
                }

                if (expectedPermission && !hasAccess(expectedPermission)) {
                  return <PermissionDeniedPage />;
                }

                if (isRestricted && accessToken) {
                  return <Redirect key={key} to={routes.home.path} />;
                }

                return (
                  <Layout>
                    <Suspense fallback={loadingIndicator || <Loading height="100vh" />}>
                      <Component />
                    </Suspense>
                  </Layout>
                );
              }}
            />
          )
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
