import React, { ReactElement, memo } from 'react';

import { StyledHeader } from '../DashboardLayout.style';
import ProfileDropdownButton from './ProfileDropdownButton';

function Header(): ReactElement {
  return (
    <StyledHeader
      alignment="row.verticalCenter"
      justifyContent="flex-end"
      paddingY="s"
      paddingX="l">
      <ProfileDropdownButton />
    </StyledHeader>
  );
}

export default memo(Header);
