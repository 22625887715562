import authRoutes from 'pages/Auth/Auth.routes';
import dashboardRoutes from 'pages/Dashboard/Dashboard.routes';
import merchantRoutes from 'pages/Merchant/Merchant.routes';

import { TRoutes } from './routes.types';

const routes: TRoutes = {
  ...dashboardRoutes,
  ...authRoutes,
  ...merchantRoutes,
};

export default routes;
