import React, { PropsWithChildren, ReactElement } from 'react';
import useFetch, {
  CachePolicies,
  IncomingOptions,
  Interceptors,
  Provider,
  RetryOpts,
} from 'use-http';

import storage, { STORAGE_KEYS } from 'helpers/storage';
import API_ENDPOINT from 'constants/apiEndpoint';
import useLogout from 'hooks/useLogout';

function FetchProvider({ children }: PropsWithChildren<unknown>): ReactElement {
  const logout = useLogout(true);
  const { post: requestRefreshToken } = useFetch(
    `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.REFRESH_TOKEN}`
  );

  const options: IncomingOptions = {
    interceptors: {
      request: handleRequest,
    } as Interceptors,
    retries: 1,
    retryOn: handleRetry,
    cachePolicy: CachePolicies.NETWORK_ONLY,
  };

  function handleRequest({ options: requestOptions }: { options: RequestInit }) {
    const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestOptions.headers.Authorization = `Bearer ${accessToken}`;
    }

    return requestOptions;
  }

  async function handleRetry({ response }: RetryOpts) {
    try {
      if (!response?.ok && response?.status === 403) {
        const refreshToken = storage.readStorage(STORAGE_KEYS.REFRESH_TOKEN);

        const { result } = await requestRefreshToken({
          token: refreshToken,
        });

        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = result;
        if (newAccessToken && newRefreshToken) {
          storage.writeStorageFromKeys({
            [STORAGE_KEYS.ACCESS_TOKEN]: newAccessToken,
            [STORAGE_KEYS.REFRESH_TOKEN]: newRefreshToken,
          });
          return true;
        }

        logout();
        return false;
      }

      return false;
    } catch (e) {
      logout();
      return false;
    }
  }

  return (
    <Provider url={process.env.REACT_APP_BASE_API_URL} options={options}>
      {children}
    </Provider>
  );
}

export default FetchProvider;
