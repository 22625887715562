import { lazy } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';

import Permission from 'constants/permission';
import { TMerchantRoutes } from './Merchant.types';

const MerchantInfo = lazy(() => import('./MerchantInfo'));
const MerchantList = lazy(() => import('./MerchantList'));
const MemberList = lazy(() => import('./MemberList'));

const routes: TMerchantRoutes = {
  merchantList: {
    path: '/merchant',
    exact: true,
    menuItemId: 'menu.merchantList',
    titleId: 'page.title.merchantList',
    description: 'Merchant List Page',
    component: MerchantList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT,
  },
  memberList: {
    path: '/member',
    exact: true,
    menuItemId: 'menu.memberList',
    titleId: 'page.title.memberList',
    description: 'Member List Page',
    component: MemberList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MEMBER,
  },
  merchantInfo: {
    path: '/merchant/:merchantId',
    exact: true,
    titleId: 'page.title.merchantList',
    description: 'Merchant Info Page',
    component: MerchantInfo,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT,
  },
};

export default routes;
