import { ASYNC_NOOP } from 'constants/noop';

import { TDocumentTypesDispatch, TDocumentTypesState } from './DocumentTypesProvider.types';

export const INITIAL_STATE: TDocumentTypesState = {
  documentTypes: [],
};

export const INITIAL_DISPATCH: TDocumentTypesDispatch = {
  getDocumentTypes: ASYNC_NOOP,
};
