import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TAccessToken, TChildrenOnly } from 'types/common';
import parseJwt from 'helpers/parseJwt';
import storage, { STORAGE_KEYS } from 'helpers/storage';
import Permission from 'constants/permission';
import { TPermissionContext, TPermissionState } from './PermissionProvider.types';
import { INITIAL_STATE } from './PermissionProvider.constants';

const permissionContext = createContext<TPermissionContext>(INITIAL_STATE);

function PermissionProvider({ children }: TChildrenOnly): ReactElement {
  const [permissions, setPermissions] = useState<Permission[]>(
    INITIAL_STATE.permissionState.permissions
  );

  useEffect(() => {
    const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      handleSetPermissions(accessToken);
    }
  }, []);

  function handleSetPermissions(token: string) {
    const parsedJwt = parseJwt(token) as TAccessToken;
    setPermissions(parsedJwt.roles);
  }

  function checkPermission(expectedPermission: Permission) {
    return permissions.includes(expectedPermission);
  }

  const value = useMemo(
    (): TPermissionContext => ({
      permissionState: {
        permissions,
        setPermissions: handleSetPermissions,
        hasAccess: checkPermission,
      },
    }),
    [permissions]
  );

  return <permissionContext.Provider value={value}>{children}</permissionContext.Provider>;
}

export default PermissionProvider;

export const usePermissionContext = (): TPermissionContext => useContext(permissionContext);
export const usePermissionState = (): TPermissionState =>
  useContext(permissionContext).permissionState;
