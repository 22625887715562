import { Avatar, Layout } from 'antd';
import styled from 'styled-components';

import Box from 'components/core/Box';

export const StyledLayout = styled(Layout)`
  display: flex;
  height: 100vh;
`;

export const ContentLayout = styled(Layout.Content)`
  flex: 1;
  overflow-y: auto;
`;

export const StyledHeader = styled(Box)`
  height: 48px;
  box-shadow: 0px 5px 20px -5px rgba(220, 220, 220, 0.81);
`;

export const StyledProfileAvatar = styled(Avatar)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};
`;
