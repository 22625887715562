import styled, { keyframes } from 'styled-components';

import { WamoIcon } from 'components/Icons';

const opacityAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
    transform: scale(85%);
  }
  100% {
    opacity: 1;
  }
`;

export const StyledWamoIcon = styled(WamoIcon)`
  height: 60px;
  animation: ${opacityAnimation} 1.5s infinite;
`;
