import { lazy } from 'react';

import { TAuthRoutes } from './Auth.types';

const Login = lazy(() => import('./Login'));

const routes: TAuthRoutes = {
  login: {
    path: '/login',
    exact: true,
    titleId: 'page.title.login',
    description: 'Login Page',
    component: Login,
    isPrivate: false,
    isRestricted: true,
  },
};

export default routes;
