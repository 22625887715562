const API_ENDPOINT = {
  REFRESH_TOKEN: '/v1/refresh',
  LOGIN: '/v1/login',
  ADMIN_USER: '/v1/admin-user',
  ADMIN_USER_ME: '/v1/admin-user/me',
  ADMIN_USER_SEARCH: '/v1/admin-user/search',
  ADMIN_ROLE: '/v1/admin-role',
  MERCHANTS: '/v1/merchants',
  MERCHANT_APPLICATIONS: '/v1/applications',
  MERCHANT_BILLING_PACKAGE_DETAIL: '/v1/merchants/{id}/billing',
  TRANSACTIONS: '/v1/transactions',
  SEND_MONEY_BENEFICIARY: '/v1/transactions/send-money/beneficiary',
  SEND_MONEY_TREASURY: '/v1/transactions/send-money/treasury',
  MEMBERS: '/v1/members',
  LEDGERS: '/v1/ledgers',
  BENEFICIARIES: '/v1/beneficiaries',
  CARDS: '/v1/cards',
  ADDRESSES: '/v1/addresses',
  DIRECTORS: '/v1/directors',
  DIRECTORS_ADD: '/v1/merchants/{merchantId}/directors',
  DIRECTORS_UPDATE: '/v1/directors/{id}',
  DIRECTORS_DOCUMENTS: '/v1/directors/{id}/documents',
  ACCOUNT_OWNER_UPDATE: '/v1/merchants/{merchantId}/account-owner',
  SHAREHOLDERS_ADD: '/v1/merchants/{merchantId}/shareholders',
  SHAREHOLDERS_UPDATE: '/v1/shareholders/{id}',
  SHAREHOLDERS: '/v1/shareholders',
  SHAREHOLDERS_DOCUMENTS: '/v1/shareholders/{id}/documents',
  ONBOARDING: '/v1/onboarding',
  ONBOARDING_V2: '/v2/onboarding',
  ONBOARDING_MANUAL_REVIEW_STATUSES: '/v1/onboarding/manual-review-statuses',
  UPDATE_ONBOARDING_MANUAL_REVIEW_STATUSES: '/v1/onboarding/{merchantId}/manual-review-statuses',
  COUNTRIES: '/v1/countries',
  COMPLIANCE_REQUESTS: '/v1/compliance-requests',
  CREATE_COMPLIANCE_REQUEST: '/v2/compliance-requests',
  DOCUMENTS: '/v1/merchants/documents',
  DOCUMENT_TYPES: '/v1/merchants/documents/types',
  PAYMENTS: '/v1/payments',
  PAYOUTS: '/v1/payouts',
  AUTOMATED_BULK_TRANSFERS: '/v1/bulk-transfers/automated-files',
  FEE_TYPES: '/v1/fee-types',
  BILLING_PACKAGES: '/v1/billing-packages',
  BILLING_PACKAGE_DETAIL: '/v1/billing-packages/{id}',
  MERCHANT_FEES: '/v1/merchant-fees',
  CASHBACK_CAMPAIGNS: '/v1/cashback-campaigns',
  CASHBACK_CAMPAIGNS_PAYOUTS_SEND: '/v1/cashback-campaigns/payouts/send',
  CASHBACK_CAMPAIGNS_PAYOUT_DETAILS: '/v1/cashback-campaigns/detail',
  AUDIT_LOGS: '/v1/audit-logs',
  AUDIT_LOGS_EVENT_TYPES: '/v1/audit-logs/event-types',
  ONBOARDING_REJECT_REASONS: '/v1/onboarding/reject-reasons',
  COMPLIANCE_REQUESTS_LABELS: '/v1/compliance-requests/labels',
  VISA_RECONCILIATION_FILE: '/v1/visa-reconciliation/files',
};

export default API_ENDPOINT;
