import { Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { color, margin, maxWidth, padding, width } from 'styled-system';

export const StyledAntdButton = styled(AntdButton)`
  ${color}
  ${padding}
  ${margin}
  ${width}
  ${maxWidth}
`;
