import React, { ReactElement, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import AppRouter from 'router/AppRouter';

import AllContextProvider from 'context/AllContextProvider';

import 'configs/i18n';

function App(): ReactElement {
  useEffect(() => {
    Spin.setDefaultIndicator(<LoadingOutlined spin />);
  }, []);

  return (
    <AllContextProvider>
      <AppRouter />
    </AllContextProvider>
  );
}

export default App;
