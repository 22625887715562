import React, { ReactElement } from 'react';

import { Text } from 'components/core/Typography';

import { TButtonProps } from './Button.types';
import { StyledAntdButton } from './Button.styles';

function Button({ id, children, labelProps, testID, type, ...rest }: TButtonProps): ReactElement {
  return (
    <StyledAntdButton {...rest} type={type} data-testid={testID}>
      {id && <Text id={id} {...labelProps} color="inherit" />}
      {children}
    </StyledAntdButton>
  );
}

export default Button;
