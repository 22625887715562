import React, { ReactElement } from 'react';

import Box from 'components/core/Box';

import { StyledWamoIcon } from './Loading.styles';
import { TLoadingProps } from './Loading.types';

function Loading({ height }: TLoadingProps): ReactElement {
  return (
    <Box backgroundColor="layoutBackground" alignment="center" height={height}>
      <Box marginBottom="xl" alignment="row.verticalCenter">
        <StyledWamoIcon height={50} />
      </Box>
    </Box>
  );
}

export default Loading;
