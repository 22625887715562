import React, { createContext, useContext, useState } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';

import { INITIAL_DISPATCH, INITIAL_STATE } from './DocumentTypesProvider.constants';
import { TDocumentTypesContext, TDocumentTypesState } from './DocumentTypesProvider.types';

const documentTypesContext = createContext<TDocumentTypesContext>({
  documentTypesState: INITIAL_STATE,
  documentTypesDispatch: INITIAL_DISPATCH,
});

function DocumentTypesProvider({ children }: TChildrenOnly) {
  const [state, setState] = useState<TDocumentTypesState>(INITIAL_STATE);
  const { get } = useFetch(API_ENDPOINT.DOCUMENT_TYPES);

  async function handleFetchDocumentTypes() {
    const response = await get();
    if (response?.result) {
      setState({ documentTypes: response.result });
    }
  }

  const value: TDocumentTypesContext = {
    documentTypesState: state,
    documentTypesDispatch: {
      getDocumentTypes: handleFetchDocumentTypes,
    },
  };

  return <documentTypesContext.Provider value={value}>{children}</documentTypesContext.Provider>;
}

export default DocumentTypesProvider;

export const useDocumentTypesContext = (): TDocumentTypesContext =>
  useContext(documentTypesContext);
