import React, { ReactElement } from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Button from 'components/core/Button';
import Box from 'components/core/Box';

import routes from 'router/routes';

function PermissionDeniedPage(): ReactElement {
  const { t } = useTranslation();
  return (
    <Box alignment="center" backgroundColor="secondaryBackground" height="100vh">
      <Box backgroundColor="primaryBackground" width="800px" padding="xxl" borderRadius="8px">
        <Result
          status="403"
          title={t('pages.permissionDenied.title')}
          subTitle={t('pages.permissionDenied.description')}
          extra={
            <NavLink to={routes.home.path} exact strict>
              <Button type="primary" id="button.backHome" />
            </NavLink>
          }
        />
      </Box>
    </Box>
  );
}

export default PermissionDeniedPage;
