import { TSpacing } from './theme.types';

const spacing: TSpacing = {
  none: 0,
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
  xl: 36,
  xxl: 40,
};

export default spacing;
