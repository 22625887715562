import storage, { STORAGE_KEYS } from 'helpers/storage';

function useLogout(shouldRedirect?: boolean): () => void {
  function onLogout() {
    storage.removeStorageFromKeys([
      STORAGE_KEYS.ACCESS_TOKEN,
      STORAGE_KEYS.REFRESH_TOKEN,
      STORAGE_KEYS.SIDEBAR_COLLAPSED,
      STORAGE_KEYS.ACCOUNT,
    ]);
    window.location.replace(shouldRedirect ? window.location.href : window.location.origin);
  }

  return onLogout;
}

export default useLogout;
